<template>
    <div style="margin: -60px;">
        <h4>Map Visualisation</h4>
        <gmap-map
        :center="center"
        :zoom="zoom"
        style="width:100%; height: 1024px;margin: -40px;"

      >
        <gmap-info-window
          v-if="selectedMarker !== null"
          :options="infoOptions"
          :position="infoWindowPos"
          :opened="infoWinOpen"
          @closeclick="infoWinOpen=false"
        >
          <template>
            <marker-detail :marker="selectedMarker" />
          </template>
        </gmap-info-window>
        <gmap-cluster>
          <gmap-marker
            :key="index"
            v-for="(m, index) in markers"
            :position="m.position"
            :title="m.title"
            :clickable="true"
            @click="toggleInfoWindow(m,index)"
          />
        </gmap-cluster>
      </gmap-map>
    </div>
</template>
<script>
import  {mapGetters} from 'vuex'
import GmapCluster from 'vue2-google-maps/dist/components/cluster'
import MarkerDetail from '../../components/host/host/marker.vue'

export default {
    components: {GmapCluster, MarkerDetail},
    data() {
        return {
            markers: [],
            selectedMarker: null,
            infoContent: '',
            infoWindowPos: null,
            infoWinOpen: false,
            currentMidx: null,
            // optional: offset, //infowindow so it visually sits nicely on top of our marker
            infoOptions: {
                pixelOffset: {
                width: 0,
                height: -35
                }
            }
        }
    },
    mounted(){
        this.updateMarkers()
    },
    watch: {
        hosts: {
            handler(){
                this.updateMarkers()
            },
            deep: true
        }
    },
    methods: {
        updateMarkers(){
            this.hosts.forEach(item => {
                    if(item.lat !== null && item.long !== null){
                        this.markers.push({
                            title: item.uid,
                            position: {lat: item.lat, lng: item.long}
                        })
                    }
                })
        },
        toggleInfoWindow (marker, idx) {
            this.selectedMarker = marker
            this.infoWindowPos = marker.position
            //      this.infoContent = 'Code Elevage: ' + marker.details.code + '<br/>' + ' Type: ' + marker.details.type

            // check if its the same marker that was selected if yes toggle
            if (this.currentMidx === idx) {
                this.infoWinOpen = !this.infoWinOpen
            }
            // if different marker set infowindow to open and reset current marker index
            else {
                this.infoWinOpen = true
                this.currentMidx = idx
            }
        }
    },
    computed: {
        ...mapGetters({
            hosts: 'host/hosts'
        }),
        zoom () {
            return 7
        },
        center () {
            if (this.markers.length > 0) {
                let marker = this.markers[0].position
                return {
                    lat: marker.lat,
                    lng: marker.lng
                }
            }
            return {
                lat: 0,
                lng: 0
            }
        }
    }
}
</script>